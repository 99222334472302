<template>
  <el-card>
    <div slot="header">
      <h3>我的收藏</h3>
    </div>
    <div class="body">
      <el-form :inline="true" :model="query" class="text-center">
        <el-form-item>
          <el-date-picker
              v-model="query.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="query.id" placeholder="请输入ID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>

      <el-row class="content">
        <el-col class="item" :span="8" v-for="i in list" :key="i">
          <el-row>
            <el-col :span="10">
              <el-image :src="i.h_url" fit="fill"></el-image>
            </el-col>
            <el-col :span="14">
              <h3>{{i.uname}}</h3>
              <p>年龄：25岁</p>
              <p>学历：医学博士</p>
              <p>专业：心脑专业</p>

              <el-button type="primary" size="mini">咨询</el-button>
              <el-button type="text"  @click="cancel(i.bid)" >取消收藏</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

<!--      <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination> -->

    </div>
  </el-card>
</template>

<script>
  import axios from "axios";
  export default {
    name: "CollectIndex",
    data() {
      return {
		list:[],
        query: {},
        url: require("../../../assets/avatar/上官芝.jpg")
      }
    },
	created(){ 
		const params = new URLSearchParams(); 
	    params.append('uid', sessionStorage.getItem('uid'));
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getCollect',params)
		.then((response) => {   
			this.list = response.data.data; 
		}) 
		.catch(function(error) {
		  console.log(error);
		});  
	},
	 methods: {
		 cancel(e) { 
		   
		   const params = new URLSearchParams();
		   params.append('uid', sessionStorage.getItem('uid'));
		   params.append('bid', e); 
		   axios.post(this.apiUrl+'/api/user/delCollection',params)
		   .then((response) => {   	
				this.$message.success('操作成功!');
		     window.location.reload();	
		   }) 
		   .catch(function(error) {
		     console.log(error);
		   });  
		   
		   
		   
      }
	}
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    .el-card__header {
      h3 {
        margin: 0;
        padding: 0;
      }
    }

    .content {
      padding: 20px;

      .item {
        margin-bottom: 40px;

        h3 {
          margin-top: 0px;
          padding-left: 10px;
        }

        .el-image {
          width: 125px;
          height: 130px;
        }

        p {
          margin: 2px 0px;
          color: #666666;
          font-size: 12px;
          padding-left: 10px;
        }

        .el-button {
          margin-top: 10px;
          margin-left: 10px;
          width: 80px;

          &.el-button--text{
            margin-left: 5px;
            color: $green;
            width: 65px;
          }
        }
      }
    }

    .el-pagination {
      text-align: center;
      margin-top: 20px;
    }
  }
</style>
